import '../style/About.css';

export function About(){
    return(
        <div className="about">
            <div className="introduction">
                <h5>Hi, my name is</h5>
                <h1>Daniel Alessi.</h1>
                <p>I love building full stack applications.</p>
            </div>
        </div>
    )
}